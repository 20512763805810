import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout>
    <SEO title="Release notes for McPiper App - GitLab CI/CD pipelines monitoring from macOS status bar" currentUrl={location.pathname} />

    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Release notes</h1>
        </div>

        <h2>1.3.0 January 2020</h2>
        <p>Support for private GitLab instances. Only 1 server is supported at the moment.</p>
        <p>
          Various fixes and improvements.
        </p>
      
        <h2>1.2.0 November 2020</h2>
        <p>
          Small UX improvement.
        </p>
        <p>
          Failed pipeline notification now has `View` action which takes you to 
          GitLab pipeline page in your default browser.
        </p>

        <h2>1.1.3 July 2020</h2>
        <p>Simple notifications for failing pipelines.</p>
        <p>
          Currently works on per repo basis, i.e. if you have a failing pipeline in 
          one branch, but in another branch it's fixed, overall status will be pass.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
